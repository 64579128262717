import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import HubspotForm from "react-hubspot-form"
import Seo from "../components/seo"

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      resource(id: $id) {
        title
        uri
        content
        ebookCoverImage
        excerpt
        ebookSlide1
        ebookSlide2
        ebookSlide3
        hsFormId
        seo {
          metaDesc
          canonical
          title
          opengraphTitle
          opengraphDescription
          opengraphType
          twitterTitle
          twitterDescription
        }
      }
    }
  }
`

const ResourceTemplate = ({ data }) => {
  // console.log(data)
  const resource = data.wpcontent.resource
  const metaDesc = data.wpcontent.resource.seo.metaDesc
  const pageTitle = data.wpcontent.resource.seo.title
  // const pageType = data.wpcontent.resource.seo.opengraphType

  // let pageurl = `https://www.vantagecircle.com`
  // const uri = resource.uri
  // if(uri){
  //     pageurl+= `${uri}`
  // }

  return (
    <Layout>
      <Seo
        title={pageTitle}
        description={metaDesc}
        // meta={[
        //   {
        //     name: `description`,
        //     content: metaDesc,
        //   },
        //   {
        //     property: `og:title`,
        //     content: pageTitle,
        //   },
        //   {
        //     property: `og:description`,
        //     content: metaDesc,
        //   },
        //   {
        //     property: `og:type`,
        //     content: pageType,
        //   },
        //   {
        //     name: `twitter:title`,
        //     content: pageTitle,
        //   },
        //   {
        //     name: `twitter:description`,
        //     content: metaDesc,
        //   },
        // ]}
        // link={[
        // 	{
        // 		rel: `canonical`,
        // 		href: pageurl,
        // 	},
        // ]}
      />
      {/* <section className="bg-purple-50 mx-auto py-5 relative"> */}
      {/* <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6"> */}
      {/* <div className="site-breadcrumb sm:text-center mb-5 text-gray-200">
            <Link className="hover:underline" to="/">
              Home
            </Link>
            &nbsp;>>&nbsp;
            <Link className="hover:underline" to="/resources/">
              Resources
            </Link>
            &nbsp;>>&nbsp;
            <span
              className=""
              dangerouslySetInnerHTML={{ __html: resource.title }}
            />
          </div> */}
      {/* <h1
            className="hero-title-2"
            dangerouslySetInnerHTML={{ __html: resource.title }}
          />
          <p
            className="section-subtitle"
            dangerouslySetInnerHTML={{ __html: resource.excerpt }}
          />
          <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
            <div className="">
              <a href="#download-now" className="vc-colored-btn">
                Download Now
              </a>
            </div>
          </div> */}
      {/* </div> */}
      {/* <img
          className="absolute z-1 hero-iconsl1 hero-icons-top"
          src="https://res.cloudinary.com/vantagecircle/image/upload/w_200/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L1.png"
          alt="Guides and Ebooks"
        />
        <img
          className="absolute z-1 hero-iconsl2 hero-icons-bottom hero-icons-1"
          src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-L2.png"
          alt="Guides and Ebooks"
        />
        <img
          className="absolute z-1 hero-iconsr1 hero-icons-top"
          src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R1.png"
          alt="Guides and Ebooks"
        />
        <img
          className="absolute z-1 hero-iconsr2 hero-icons-bottom hero-icons-1"
          src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/GuidesnEbooks-Hero-R2.png.png"
          alt="Guides and Ebooks"
        /> */}
      {/* </section> */}
      {/* <div className="slant-bg bg-purple-50 slant-bg-purple py-2 sm:py-5 md:py-8 lg:py-10">
        <div className="">&nbsp;</div>
      </div> */}
      {/* <section className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6 lg:-mt-72">
                <div className="ebbok-featured-image">
                    <img className="mx-auto" src={resource.ebookCoverImage} alt={resource.title} />
                </div>
            </section> */}

      {/* <section className="ebook-carousel container text-center max-w-4xl md:-mt-36 px-7">
        <CarouselProvider
          className="relative mx-auto"
          naturalSlideWidth={110}
          naturalSlideHeight={80}
          totalSlides={3}
        >
          <Slider className="">
            <Slide index={0}>
              <img src={resource.ebookSlide1} alt="image1" />
            </Slide>
            <Slide index={1}>
              <img src={resource.ebookSlide2} alt="image2" />
            </Slide>
            <Slide index={2}>
              <img src={resource.ebookSlide3} alt="image3" />
            </Slide>
          </Slider>
          <ButtonBack className="shadow eb-carousel-btn">
            <svg
              className="mx-auto text-purple-200"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            </svg>
          </ButtonBack>
          <ButtonNext className="shadow eb-carousel-btn">
            <svg
              className="mx-auto text-purple-200"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
            </svg>
          </ButtonNext>
          <DotGroup />
        </CarouselProvider>
      </section> */}
      <section className=" bg-purple-100 w-full">
        <div className="container max-w-7xl py-10 md:py-16 mx-auto px-6">
          <div>
            <div className="md:flex">
              <div className="card-1 w-full md:w-7/12 mb-10">
                {pageTitle ===
                "The Ultimate Guide to Rewards and Recognition Guide - Free PDF" ? (
                  <img
                    className="p-0 m-0 transform w-1/2 -translate-x-2"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/Ribbon.png"
                  />
                ) : null}
                <div className="site-breadcrumb text-left mb-5 text-gray-200">
                  <a class="hover:underline" href="/">
                    Home
                  </a>
                  &nbsp;&gt;&gt;&nbsp;
                  <a class="hover:underline" href="/resources/">
                    Resources
                  </a>
                </div>
                <h1
                  class="homepage-heading"
                  dangerouslySetInnerHTML={{ __html: resource.title }}
                ></h1>
                <p className="mt-5 mb-0 text-gray-900">
                  In this guide, we will cover:
                </p>
                <ul
                  className="form-bullets list-inside orangebullets"
                  style={{ marginTop: "1.5rem !important" }}
                  dangerouslySetInnerHTML={{ __html: resource.content }}
                />
              </div>
              <div className="card-1 w-full md:w-5/12 text-gray-900 rounded-xl bg-white shadow-lg p-8 lg:p-10 md:ml-12 border border-gray-900 ebook-hb-from">
                <div className="form-section ml-0 lg:ml-4">
                  <h2 className="mt-2 mb-8 text-2xl text-gray-900">
                    Fill up to download your copy now
                  </h2>
                  <HubspotForm
                    portalId="6153052"
                    formId={resource.hsFormId}
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                  {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank">Privacy Policy</a></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-32">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section> */}
    </Layout>
  )
}

export default ResourceTemplate
